import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Status, Priority, customStylesforModal } from "../Object";
import Modal from "react-modal";
import TableComponent from "../components/table-component";

function Done() {
  const [tickets, setTickets] = useState([]);
  const token = useSelector((state) => state.userInfo.token);
  const user = useSelector((state) => state.userInfo.user);
  const [show, setshow] = useState(false);
  const [change, setChange] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  const DeleteHandler = () => {
    const deleteData = async () => {
      await axios
        .delete(`https://backend.resitic.com/api/v1/tickets/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          navigate("/done-tickets");
          setChange(true);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    deleteData();
    handleClose();
  };

  useEffect(() => {
    const fetchDataIfMaintanance = async () => {
      await axios
        .get("https://backend.resitic.com/api/v1/tickets/getMeAssignTicket", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTickets(
            response.data.data.data.rows.filter(
              (ticket) => ticket.statusId == "3"
            )
          );
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDataLoading(false);
        });
    };

    const fetchDataIfUser = async () => {
      await axios
        .get("https://backend.resitic.com/api/v1/tickets/getMeTicket", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTickets(response.data.data.totalComplate.rows);
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDataLoading(false);
        });
    };
    const fetchDataIfAdmin = async () => {
      await axios
        .get("https://backend.resitic.com/api/v1/tickets/getTicketForAdmin", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTickets(
            response.data.data.data.rows.filter(
              (ticket) => ticket.statusId == "3"
            )
          );
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDataLoading(false);
        });
    };
    const fetchDataIfNotAdmin = () => {
      user.role !== "User" ? fetchDataIfMaintanance() : fetchDataIfUser();
    };
    user.role === "Admin" ? fetchDataIfAdmin() : fetchDataIfNotAdmin();
  }, [change]);

  return (
    <>
      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row">
              <div className="col-12 col-sm-6 pt-3 pb-3">
                <h4>Completed/closed Tickets</h4>
              </div>
            </div>
            {!isDataLoading && tickets.length === 0 ? (
              <div
                className="alert alert-danger noText"
                role="alert"
                style={{ width: "50%" }}
              >
                No Tickets Found
              </div>
            ) : (
              <div className="table-responsive mt-3">
                {isDataLoading && <div className="loader"></div>}
                {!isDataLoading && (
                  <TableComponent rawData={tickets} handleShow={handleShow} />
                )}
              </div>
            )}
          </div>
        </div>
      </main>
      <Modal
        isOpen={show}
        onHide={() => setshow(false)}
        style={customStylesforModal}
      >
        <div class="modal-dialog modal-confirm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column">
              <div class="icon-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
              <h4 class="modal-title w-100">Are you sure?</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <p>
                Do you really want to delete these records? This process cannot
                be undone.
              </p>
            </div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={DeleteHandler}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Done;
