import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { customStylesforModal } from "../Object";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import TableComponent from "../components/table-component";

function Unassigned(props) {
  const [tickets, setTickets] = useState([]);
  const token = useSelector((state) => state.userInfo.token);
  const user = useSelector((state) => state.userInfo.user);
  const [show, setshow] = useState(false);
  const [change, setChange] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  const DeleteHandler = () => {
    const deleteData = async () => {
      await axios
        .delete(`https://backend.resitic.com/api/v1/tickets/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          navigate("/unassigned-tickets");
          setChange(true);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    deleteData();
    handleClose();
  };

  useEffect(() => {
    const fetchDataIfUser = async () => {
      await axios
        .get("https://backend.resitic.com/api/v1/tickets/getMeTicket", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const UnAssignedTickets = [];

          response.data.data.totalComplate.rows.map((ticket) =>
            ticket.tagId == null ? UnAssignedTickets.push(ticket) : ""
          );
          response.data.data.totalInProgress.rows.map((ticket) =>
            ticket.tagId == null ? UnAssignedTickets.push(ticket) : ""
          );
          response.data.data.totalPending.rows.map((ticket) =>
            ticket.tagId == null ? UnAssignedTickets.push(ticket) : ""
          );
          setTickets(UnAssignedTickets);
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDataLoading(false);
        });
    };
    const fetchDataIfAdmin = async () => {
      await axios
        .get("https://backend.resitic.com/api/v1/tickets/getTicketForAdmin", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (props.assigned == true) {
            setTickets(
              response.data.data.data.rows.filter(
                (ticket) => ticket.tagId != null
              )
            );
          } else {
            setTickets(
              response.data.data.data.rows.filter(
                (ticket) => ticket.tagId == null
              )
            );
          }

          setIsDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDataLoading(false);
        });
    };
    user.role === "Admin" ? fetchDataIfAdmin() : fetchDataIfUser();
  }, [change, props.assigned]);

  return (
    <>
      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row">
              <div className="col-12 col-sm-6 pt-3 pb-3">
                <h4>{props.assigned ? "Assigned" : "Unassigned"} Tickets</h4>
              </div>
            </div>
          </div>
          {!isDataLoading && tickets.length === 0 ? (
            <div
              className="alert alert-danger noText"
              role="alert"
              style={{ width: "50%" }}
            >
              No Tickets Found
            </div>
          ) : (
            <div className="table-responsive mt-3">
              {isDataLoading && <div className="loader"></div>}
              {!isDataLoading && tickets.length !== 0 && (
                <TableComponent rawData={tickets} handleShow={handleShow} />
              )}
            </div>
          )}
        </div>
      </main>
      {/* ---------------------------Modal----------------------- */}
      <Modal
        isOpen={show}
        onHide={() => setshow(false)}
        style={customStylesforModal}
      >
        <div class="modal-dialog modal-confirm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column">
              <div class="icon-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
              <h4 class="modal-title w-100">Are you sure?</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <p>
                Do you really want to delete these records? This process cannot
                be undone.
              </p>
            </div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={DeleteHandler}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Unassigned;
