import React from "react";

const COLOR = {
  GREEN: "#39d900",
  YELLOW: "rgb(255 115 0)",
  RED: "#ff0000",
};

const GENERAL_ADMIN_MENU = 3;

const CONSTANTS = {
  COMMUNE_TABLE_COLUMN: [
    { id: 1, value: 'ID', align: 'center' },
    // { id: 2, value: "Ticket ID", align: "center" },
    { id: 3, value: 'Status' },
    { id: 4, value: 'Subject' },
    { id: 5, value: 'Priority', align: 'center' },
    { id: 6, value: 'Maintenance' },
    { id: 7, value: 'Action' }
  ],

  NAVIGATION_MENU: [
    {
      id: 1,
      menuName: 'All tickets',
      isIcon: true,
      navigateTo: '/',
      role: { User: true, Admin: true, Maintenance: true },
      isHide: false
    },
    {
      id: 10,
      menuName: 'New Ticket',
      isIcon: true,
      navigateTo: '/create-ticket',
      role: { User: true, Admin: true },
      isHide: false
    },
    {
      id: 2,
      menuName: 'No Status',
      isIcon: true,
      navigateTo: '/unresolved-tickets',
      role: { User: true, Admin: true, Maintenance: true },
      isHide: false
    },
    {
      id: 3,
      menuName: 'Pending',
      isIcon: true,
      navigateTo: '/in-progress-tickets',
      role: { User: true, Admin: true, Maintenance: true },
      isHide: false
    },
    {
      id: 4,
      menuName: 'Unassigned',
      isIcon: true,
      navigateTo: '/unassigned-tickets',
      role: { User: true, Admin: true },
      isHide: false
    },
    {
      id: 5,
      menuName: 'Assigned',
      isIcon: true,
      navigateTo: '/assigned-tickets',
      role: { User: true, Admin: true },
      isHide: false
    },
    {
      id: 6,
      menuName: 'Complete/Closed',
      isIcon: true,
      navigateTo: '/done-tickets',
      role: { User: true, Admin: true, Maintenance: true },
      isHide: false
    },
    {
      id: 7,
      menuName: 'Manage Businesses',
      isIcon: true,
      navigateTo: '/manage-businesses',
      role: { SuperAdmin: true },
      isHide: false
    },
    {
      id: 8,
      menuName: 'Send User Notification',
      isIcon: true,
      navigateTo: '/user-notification',
      role: { Admin: true },
      isHide: true
    },
    {
      id: 9,
      menuName: 'Add a Business',
      isIcon: true,
      navigateTo: '/add-business',
      role: { SuperAdmin: true },
      isHide: false
    },
    {
      id: 11,
      menuName: 'Manage Users',
      isIcon: true,
      navigateTo: '/manage-user',
      role: { Admin: true },
      isHide: false
    }
  ],

  STATUS: {
    1: 'No Status',
    2: 'Pending',
    3: 'Completed',
    4: { value: 'Unassigned', color: COLOR.RED },
    5: { value: 'Assigned', color: COLOR.GREEN }
  },
  PRIORITY: {
    1: { value: 'Low', color: COLOR.GREEN },
    2: { value: 'Medium', color: COLOR.YELLOW },
    3: { value: 'High', color: COLOR.RED }
  }
}

export const NavBarIcon = ({ menuItem }) => {
  switch (menuItem) {
    case "Manage Businesses":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M3 3h18v18H3zM21 9H3M21 15H3M12 3v18" />
        </svg>
      );
    case "Send User Notification":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
        </svg>
      );
    case "Add a Business":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
          <line x1="12" y1="11" x2="12" y2="17"></line>
          <line x1="9" y1="14" x2="15" y2="14"></line>
        </svg>
      );
    case "All tickets":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
          <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
        </svg>
      );
    case "No Status":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-file"
        >
          <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
          <polyline points="13 2 13 9 20 9"></polyline>
        </svg>
      );
    case "Pending":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2" />
        </svg>
      );
    case "Unassigned":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="8.5" cy="7" r="4"></circle>
          <line x1="20" y1="8" x2="20" y2="14"></line>
          <line x1="23" y1="11" x2="17" y2="11"></line>
        </svg>
      );
    case "Assigned":
      return (
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M16 7L12 11"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      );
    case "Complete/Closed":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
      );
    case "New Ticket":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
          <path d="M14 3v5h5M12 18v-6M9 15h6" />
        </svg>
      );
    case "Manage Users":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
        </svg>
      );

    default:
      return <></>;
  }
};

export default CONSTANTS;
