import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function CreateTicket() {
  const token = useSelector((state) => state.userInfo.token);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFileName, setUploadFileName] = useState(
    "Upload picture (Optional)"
  );
  const navigate = useNavigate();

  const data = new FormData();

  const onfileChange = (e) => {
    setUploadFile(e.target.files[0]);
    setUploadFileName(e.target.files[0].name);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    data.append("subject", e.target.elements["subject"].value);
    data.append("priorityId", e.target.elements["priority"].value);
    data.append("description", e.target.elements["description"].value);
    // data.append("uploads", uploadFile);
    try {
      const response = await axios.post(
        `https://backend.resitic.com/api/v1/tickets`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ContentType: "multipart/form-data",
          },
        }
      );
      setIsSubmitting(false);
      navigate("/");
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  return (
    <>
      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row">
              <div className="col-lg-6 col-sm-12 pb-3 sticky-top sticky-form">
                <h3 className="pt-3">Create New Ticket</h3>
                <div className="card form-holder mt-3">
                  <div className="card-body">
                    <form className="" onSubmit={submitHandler}>
                      <div className="text-group">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          required="required"
                          placeholder=" "
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Subject</label>
                      </div>

                      <div className="text-group">
                        <select
                          className="form-control"
                          id="priority"
                          required="required"
                          defaultValue=""
                        >
                          <option value=""></option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                        </select>
                        <label htmlFor="textarea" className="input-label">
                          Priority
                        </label>
                        <i className="bar"></i>
                      </div>
                      {/* <div className="form-group">
                        <div className="custom-file pmd-custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="ticket-photo"
                            required=""
                            accept=".png, .jpg, .jpeg"
                            onChange={onfileChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="ticket-photo"
                          >
                            {uploadFileName}
                          </label>
                        </div>
                      </div> */}
                      <div className="form-group group">
                        <div className="text-group">
                          <textarea
                            required="required"
                            className="form-control"
                            rows="3"
                            placeholder=" "
                            name="description"
                          ></textarea>
                          <label htmlFor="textarea" className="input-label">
                            Description
                          </label>
                          <i className="bar"></i>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                        >
                          {isSubmitting ? "Creating...." : "Create"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default CreateTicket;
