import React from "react";

export default function Notification(props) {
  return (
    <div className="top-notification">
      <p className={`message ${props.type == "success" ? "green" : "red"}`}>
        {props.message}
      </p>
    </div>
  );
}

Notification.defaultProps = {
  type: "success",
  message: "Notification Message",
};
