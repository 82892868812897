import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Notification from "../components/notification";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { mobileNumberFormatter } from "../Utils/Healper";

function EditUser() {
  const token = useSelector((state) => state.userInfo.token);
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [state, updateState] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState("");
  const [isNotification, setIsNotification] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");

  const generateNotification = (type, message) => {
    setIsNotification({ type, message });
    setTimeout(() => setIsNotification(null), 1500);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      e.target.elements["password"].value !==
      e.target.elements["confirmPassword"].value
    ) {
      generateNotification("fail", "Confirm Password Not matched");
      return;
    }

    if (e.target.elements["zipCode"].value.length !== 5) {
      generateNotification("fail", "Enter Valid 5 Digit Zip Code");
      return;
    }

    if (
      e.target.elements["mobile"].value
        .replace("(", "")
        .replace(")", "")
        .replace(" - ", "")
        .replace(" ", "").length != 10
    ) {
      generateNotification("fail", "Please Enter Valid 10 digit Mobile Number");
      return;
    }

    const updatedUser = {
      firstName: e.target.elements["firstname"].value,
      lastName: e.target.elements["lastname"].value,
      email: e.target.elements["email"].value,
      password: e.target.elements["password"].value,
      mobile: e.target.elements["mobile"].value
        .replace("(", "")
        .replace(")", "")
        .replace(" - ", "")
        .replace(" ", ""),
      country: e.target.elements["country"].value,
      state: e.target.elements["state"].value,
      city: e.target.elements["city"].value,
      zipCode: e.target.elements["zipCode"].value,
      address: e.target.elements["address"].value,
    };

    await axios
      .patch(
        `https://backend.resitic.com/api/v1/users/updateUserDetails/${id}`,
        updatedUser,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 2000);
        updateState((previous) => !previous);
      })
      .catch((err) => {
        setIsError("Some thing went wrong try check entered Data");
        setTimeout(() => setIsError(""), 2000);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.resitic.com/api/v1/users/getOne/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser(response.data.data.users);

        setMobileNumber(`${response.data.data.users.mobile}`);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [state]);
  return (
    <>
      {isNotification != null && (
        <Notification
          type={isNotification.type}
          message={isNotification.message}
        />
      )}
      {isSuccess && (
        <Notification type="success" message="User Successfully updated" />
      )}
      {isError != "" && <Notification type="fail" message={isError} />}
      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-6 pb-3">
                <h3 className="pt-3">Update User</h3>

                <div className="card form-holder mt-3">
                  <div className="card-body">
                    <form className="" onSubmit={submitHandler}>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="firstname"
                          required="required"
                          placeholder=" "
                          defaultValue={user.firstName}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>First Name</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="lastname"
                          required="required"
                          placeholder=" "
                          defaultValue={user.lastName}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Last Name</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder=" "
                          required="required"
                          defaultValue={user.email}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Email</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="password"
                          placeholder=" "
                          required="required"
                          defaultValue={user.password}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Password</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="confirmPassword"
                          placeholder=" "
                          required="required"
                          defaultValue={user.password}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Confirm Password</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="mobile"
                          placeholder=" "
                          required="required"
                          value={mobileNumberFormatter(mobileNumber)}
                          onInput={(e) => setMobileNumber(e.target.value)}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Mobile Number</label>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          required="required"
                          placeholder=" "
                          defaultValue={user.address}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Address</label>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          required="required"
                          placeholder=" "
                          defaultValue={user.city}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>City</label>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="state"
                          placeholder=" "
                          required="required"
                          defaultValue={user.state}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>State</label>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="zipCode"
                          placeholder=" "
                          defaultValue={user.zipCode}
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Zip Code</label>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="country"
                          placeholder=" "
                          required="required"
                          defaultValue={user.country}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Country</label>
                      </div>

                      {/* Commented */}
                      {/* <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="notes"
                          required="false"
                          defaultValue={user.notes}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Note</label>
                      </div> */}

                      <div className="form-group group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                        >
                          UPDATE
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="cartoon-img">
                <img className="img-fluid" src="assets/img/images.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default EditUser;
