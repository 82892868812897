import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notification from "../components/notification";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { mobileNumberFormatter } from "../Utils/Healper";

function AddUser() {
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isDataSubmitting, setIsDataSubmitting] = useState(false);
  const [isNotification, setIsNotification] = useState(null);
  const token = useSelector((state) => state.userInfo.token);
  const navigate = useNavigate();
  const generateNotification = (type, message) => {
    setIsNotification({ type, message });
    setTimeout(() => setIsNotification(null), 1500);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      e.target.elements["password"].value !==
      e.target.elements["confirmPassword"].value
    ) {
      generateNotification("fail", "Confirm Password Not matched");
      return;
    }
    if (e.target.elements["zipCode"].value.length !== 5) {
      generateNotification("fail", "Enter Valid 5 Digit Zip Code");
      return;
    }

    const data = new FormData();
    if (
      e.target.elements["mobile"].value
        .replace("(", "")
        .replace(")", "")
        .replace(" - ", "")
        .replace(" ", "").length != 10
    ) {
      setIsMobileNumberValid(false);
      generateNotification("fail", "Please Enter Valid 10 digit Mobile Number");
      return;
    } else {
      setIsMobileNumberValid(true);
    }

    setIsDataSubmitting(true);
    data.append("firstName", e.target.elements["firstname"].value);
    data.append("lastName", e.target.elements["lastname"].value);
    data.append("email", e.target.elements["email"].value);
    data.append("password", e.target.elements["password"].value);
    data.append(
      "mobile",
      e.target.elements["mobile"].value
        .replace("(", "")
        .replace(")", "")
        .replace(" - ", "")
        .replace(" ", "")
    );
    data.append("country", e.target.elements["country"].value);
    data.append("state", e.target.elements["state"].value);

    data.append("city", e.target.elements["city"].value);
    data.append("zipCode", e.target.elements["zipCode"].value);
    data.append("address", e.target.elements["address"].value);
    data.append("role", e.target.elements["role"].value);

    await axios
      .post("https://backend.resitic.com/api/v1/users/addUser", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === "fail") {
          console.log(response.data.message);
          generateNotification("fail", response.data.message);
        } else {
          navigate("/manage-user");
        }

        setIsDataSubmitting(false);
      })
      .catch((err) => {
        setIsDataSubmitting(false);
        console.log(err);
      });
  };
  return (
    <>
      {isNotification != null && (
        <Notification
          type={isNotification.type}
          message={isNotification.message}
        />
      )}
      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-6 pb-3">
                <h3 className="pt-3">Add New User Or Maintenance</h3>

                <div className="card form-holder mt-3">
                  <div className="card-body">
                    <form className="" onSubmit={submitHandler}>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="firstname"
                          required="required"
                          placeholder=" "
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>First Name *</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="lastname"
                          required="required"
                          placeholder=" "
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Last Name *</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          required="required"
                          placeholder=" "
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Email *</label>
                      </div>

                      <div className="group form-group">
                        <select
                          className="form-control"
                          id="role"
                          required="required"
                          defaultValue=""
                        >
                          <option value=""></option>
                          <option value="User">User</option>
                          <option value="Maintenance">Maintenance</option>
                        </select>
                        <label htmlFor="textarea" className="input-label">
                          Role *
                        </label>
                        <i className="bar"></i>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="password"
                          required="required"
                          placeholder=" "
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Password *</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="confirmPassword"
                          required="required"
                          placeholder=" "
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Confirm Password *</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="mobile"
                          required="required"
                          placeholder=" "
                          value={mobileNumberFormatter(mobileNumber)}
                          onInput={(e) => setMobileNumber(e.target.value)}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>
                          Mobile Number *
                          {!isMobileNumberValid && (
                            <span className="Warning">
                              Please Enter Valid 10 digit Mobile number
                            </span>
                          )}
                        </label>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          placeholder=" "
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Address *</label>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          placeholder=" "
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>City *</label>
                      </div>
                      <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="state"
                          placeholder=" "
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>State *</label>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          placeholder=" "
                          className="form-control"
                          id="zipCode"
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Zip Code *</label>
                      </div>

                      <div className="group form-group">
                        <input
                          type="text"
                          placeholder=" "
                          className="form-control"
                          id="country"
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Country *</label>
                      </div>

                      {/* Commented */}
                      {/* <div className="group form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="note"
                          required="false"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Note</label>
                      </div> */}

                      <div className="form-group group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                          disabled={isDataSubmitting}
                        >
                          {isDataSubmitting ? "ADDING...." : "ADD"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AddUser;
