import React from "react";
import { Link } from "react-router-dom";

function ForgotPassword() {
  return (
    <>
      <section className="login-body">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-12 col-xs-12">
              <div className="card form-holder">
                <div className="card-body">
                  <a
                    className="navbar-brand mb-5 text-center d-block"
                    href="google.com"
                  >
                    <img alt="" src="assets/img/logo.png" />
                  </a>
                  <h3 className="pb-5">Reset Your Password</h3>
                  <form className="text-left">
                    <div className="group form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="name"
                        placeholder=" "
                        required="required"
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Enter Your Email Address</label>
                    </div>
                    <div className="form-group ">
                      <button type="button" className="btn btn-primary btn-lg">
                        Send
                      </button>
                    </div>
                    <div className="group form-group ">
                      Already have an password?{" "}
                      <Link to="/login"> Sign in</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="cartoon-img">
            <img
              className="img-fluid"
              src="assets/img/support-img.png"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
