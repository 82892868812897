import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Modal from "react-modal";
import { customStylesforModal } from "../Object";
import "./ManageBusiness.css";

function ManageBusiness() {
  const token = useSelector((state) => state.userInfo.token);
  const [businesses, setBusiness] = useState([]);
  const [show, setshow] = useState(false);
  const [change, setChange] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { id } = useParams();
  let navigate = useNavigate();

  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  const DeleteHandler = () => {
    const deleteData = async () => {
      await axios
        .delete(
          `https://backend.resitic.com/api/v1/users/deleteBusiness/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          navigate("/manage-businesses");
          setChange((lastState) => !lastState);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    deleteData();
    handleClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.resitic.com/api/v1/users/getAllBusiness", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setBusiness(response.data.data.users.rows);
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDataLoading(false);
        });
    };
    fetchData();
  }, [change]);

  return (
    <>
      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <Dashboard />
          <div className="page-title">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-6 pt-3 pb-3 d-flex justify-content-between user-div">
                <h4>Manage Businesses</h4>
                <Link className="btn btn-primary" to="/add-business">
                  Add New Business
                </Link>
              </div>
            </div>
          </div>
          <div className="table-responsive mt-3">
            {isDataLoading && <div class="loader"></div>}
            {!isDataLoading && businesses.length != 0 && (
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Logo</th>
                    <th>Name</th>
                    <th>Business Name</th>
                    <th>Address</th>
                    <th>Email</th>
                    <th>Contact Info</th>
                    <th>Website</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {businesses.map((b) => {
                    return (
                      <>
                        <tr key={b.id}>
                          <td>{b.id}</td>
                          <td>
                            <img
                              className="logo-img-cover"
                              alt=""
                              src={`https://backend.resitic.com/uploads/${b.profilePic}`}
                            />
                          </td>
                          <td>{b.firstName}</td>
                          <td>{b.businessName}</td>
                          <td>{b.address}</td>
                          <td>{b.email}</td>
                          <td>{b.mobile}</td>
                          <td>{b.website}</td>
                          <td className="button-container">
                            <Link
                              className="action-btn btn btn-primary"
                              to={`/edit-business/${b.id}`}
                            >
                              View / Edit
                            </Link>
                            <Link
                              className="action-btn btn btn-danger "
                              to={`/manage-businesses/${b.id}`}
                              data-toggle="modal"
                              onClick={handleShow}
                            >
                              Delete
                            </Link>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            )}
            {!isDataLoading && businesses.length == 0 && (
              <div
                className="alert alert-danger noText"
                role="alert"
                style={{ width: "50%" }}
              >
                No Business Found
              </div>
            )}
          </div>
        </div>
      </main>
      {/* ---------------------------Modal----------------------- */}
      <Modal
        isOpen={show}
        onHide={() => setshow(false)}
        style={customStylesforModal}
      >
        <div class="modal-dialog modal-confirm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column">
              <div class="icon-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
              <h4 class="modal-title w-100">Are you sure?</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <p>
                Do you really want to delete these records? This process cannot
                be undone.
              </p>
            </div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={DeleteHandler}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ManageBusiness;
