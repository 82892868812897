import React from "react";
import "./Topbar.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/userSlice";
function Topbar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInfo.user);
  const logoutHandler = () => {
    dispatch(userActions.logout());
  };

  return (
    <>
      <nav className="navbar navbar-light sticky-top flex-md-nowrap px-0">
        <button
          className="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link className="mobile-logo pl-2" to="/">
          <img alt="" src="assets/img/logo.png" />
        </Link>
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0 pr-3">
          <li className="profile-nav onhover-dropdown pe-0 py-0 me-0">
            <div className="media profile-media" style={{ margin: "5px 0px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li>
                <Link to="/account">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3" />
                    <circle cx="12" cy="10" r="3" />
                    <circle cx="12" cy="12" r="10" />
                  </svg>
                  <span>Account</span>
                </Link>
              </li>
              <li>
                <Link to="/login" onClick={logoutHandler}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-log-in"
                  >
                    <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                    <polyline points="10 17 15 12 10 7"></polyline>
                    <line x1="15" y1="12" x2="3" y2="12"></line>
                  </svg>
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Topbar;
