export const mobileNumberFormatter = (value) => {
  if(value==undefined)
  return "";
  const number = (value+"")
    .replace("(", "")
    .replace(")", "")
    .replace(" - ", "")
    .replace(" ", "");
  if (number.length < 4) return number;
  if (number.length < 7) return `(${number.slice(0, 3)}) ${number.slice(3, 6)}`;
  return `(${number.slice(0, 3)}) ${number.slice(3, 6)} - ${number.slice(
    6,
    10
  )}`;
};
