import { createSlice } from "@reduxjs/toolkit";

const emptyUserObject = {
  id: NaN,
  firstname: null,
  lastname: null,
  email: null,
  mobile: NaN,
  city: null,
  state: null,
  country: null,
  address: null,
  website: null,
  role: null,
  profilePic: null,
  businessName: null,
  contactInfo: null,
};

const userSlice = createSlice({
  name: "userInfo",
  initialState: {
    isLoggedIn: localStorage.getItem("isLoggedIn"),
    token: localStorage.getItem("token"),
    user:
      localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user"))
        : emptyUserObject,
  },
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    login(state, action) {
      state.token = action.payload;
      state.isLoggedIn = true;
      localStorage.setItem("token", state.token);
      localStorage.setItem("isLoggedIn", state.isLoggedIn);
    },
    logout(state) {
      state.token = "";
      state.isLoggedIn = false;
      localStorage.setItem("token", null);
      localStorage.setItem("isLoggedIn", null);
      localStorage.removeItem("user");
    },
    setUser(state, action) {
      state.user.id = action.payload.id;
      state.user.firstname = action.payload.firstName;
      state.user.lastname = action.payload.lastName;
      state.user.email = action.payload.email;
      state.user.mobile = action.payload.mobile;
      state.user.city = action.payload.city;
      state.user.state = action.payload.state;
      state.user.country = action.payload.country;
      state.user.address = action.payload.address;
      state.user.website = action.payload.website;
      state.user.role = action.payload.role;
      state.user.profilePic = action.payload.profilePic;
      state.user.businessName = action.payload.businessName;
      state.user.contactInfo = action.payload.contactInfo;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
