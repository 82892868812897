import React from "react";
import { Link } from "react-router-dom";
import { NavBarIcon } from "../Utils/CONSTANTS";

export default function NavItem({
  isShow,
  isActive,
  menuName,
  isIcon,
  navigateTo,
}) {
  if (!isShow) return;
  return (
    <Link className={isActive ? `nav-link active` : `nav-link`} to={navigateTo}>
      {isIcon ? <NavBarIcon menuItem={menuName} /> : ""}
      {menuName}
    </Link>
  );
}

NavItem.defaultProps = {
  isActive: false,
  menuName: "",
  isIcon: true,
  navigateTo: "/",
  isShow: true,
};
