import React, { useRef, useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { userActions } from "../store/userSlice";

function Login() {
  const email = useRef();
  const password = useRef();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const submitHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const user = {
      email: email.current.value,
      password: password.current.value,
    };
    try {
      const response = await axios.post(
        "https://backend.resitic.com/api/v1/users/login",
        user
      );
      const userInfo = await axios.get(
        "https://backend.resitic.com/api/v1/users/getMe",
        {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        }
      );
      setIsLoading(false);
      dispatch(userActions.setUser(userInfo.data.data.user));
      dispatch(userActions.login(response.data.token));
      userInfo.data.data.user.role !== "SuperAdmin"
        ? navigate("/")
        : navigate("/manage-businesses");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsError(true);
    }
  };
  return (
    <>
      <section className="login-body">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-12 col-xs-12">
              <div className="card form-holder">
                <div className="card-body">
                  <a
                    className="navbar-brand mb-5 text-center d-block"
                    href="google.com"
                  >
                    <img alt="" src="/assets/img/logo.png" />
                  </a>
                  <h3 className="pb-3">Login</h3>
                  <p className="pb-5">Welcome back! Log in to your account.</p>
                  <form onSubmit={submitHandler} className="text-left">
                    <div className="group form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="name"
                        placeholder=" "
                        required="required"
                        ref={email}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Email Address</label>
                    </div>
                    <div className="group form-group">
                      <input
                        type="password"
                        className="form-control"
                        id="name"
                        placeholder=" "
                        required="required"
                        ref={password}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Password</label>
                    </div>
                    {isError && (
                      <p className="login-waning">Wrong Id or password</p>
                    )}
                    {/* <div className="group form-group">
                      <Link to="/forgot-password">Forgot password ?</Link>
                    </div> */}
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary btn-lg">
                        {!isLoading ? "Submit" : "Sending..."}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="login-cartoon-img">
              <img
                className="img-fluid"
                src="assets/img/support-img.png"
                alt=""
              />
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
