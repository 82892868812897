import "./App.css";
import Login from "./pages/Login";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword";
import Unresolved from "./pages/Unresolved";
import Home from "./pages/Home";
import Editticket from "./pages/Editticket";
import InProgress from "./pages/InProgress";
import Done from "./pages/Done";
import AddBusiness from "./pages/AddBusiness";
import ManageBusiness from "./pages/ManageBusiness";
import Unassigned from "./pages/Unassigned";
import AddUser from "./pages/AddUser";
import CreateTicket from "./pages/CreateTicket";
import UserNotification from "./pages/UserNotification";
import ManageUser from "./pages/ManageUser";
import Account from "./pages/Account";
import EditBusiness from "./pages/EditBusiness";
import EditUser from "./pages/EditUser";
import { useSelector } from "react-redux";

function App() {
  const user = useSelector((state) => state.userInfo.user);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route
        path="/"
        element={
          user.role ? (
            user.role !== "SuperAdmin" ? (
              <Home />
            ) : (
              <ManageBusiness />
            )
          ) : (
            <Login />
          )
        }
      />
      <Route path="/:id" element={<Home />} />
      <Route path="/edit-ticket/:id" element={<Editticket />} />
      <Route path="/in-progress-tickets" element={<InProgress />} />
      <Route path="/in-progress-tickets/:id" element={<InProgress />} />
      <Route path="/unresolved-tickets" element={<Unresolved />} />
      <Route path="/unresolved-tickets/:id" element={<Unresolved />} />
      <Route path="/unassigned-tickets" element={<Unassigned />} />
      <Route
        path="/assigned-tickets"
        element={<Unassigned assigned={true} />}
      />
      <Route path="/unassigned-tickets/:id" element={<Unassigned />} />
      <Route path="/done-tickets" element={<Done />} />
      <Route path="/done-tickets/:id" element={<Done />} />
      <Route path="/add-business" element={<AddBusiness />} />
      <Route path="/manage-businesses" element={<ManageBusiness />} />
      <Route path="/manage-businesses/:id" element={<ManageBusiness />} />
      <Route path="/add-user" element={<AddUser />} />
      <Route path="/create-ticket" element={<CreateTicket />} />
      <Route path="/user-notification" element={<UserNotification />} />
      <Route path="/manage-user" element={<ManageUser />} />
      <Route path="/manage-user/:id" element={<ManageUser />} />
      <Route path="/account" element={<Account />} />
      <Route path="/edit-business/:id" element={<EditBusiness />} />
      <Route path="/edit-user/:id" element={<EditUser />} />
    </Routes>
  );
}

export default App;
