import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { userActions } from "../store/userSlice";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import "./account.css";
import userImage from "../images/user.png";
import Notification from "../components/notification";

const notificationModel = (show, type, message) => {
  return {
    show: show,
    type: type == true ? "success" : "fail",
    message: message,
  };
};

function Account() {
  const user = useSelector((state) => state.userInfo.user);
  const token = useSelector((state) => state.userInfo.token);
  const [isLoading, setIsLoading] = useState(false);
  const [isPickLoading, setIsPickLoading] = useState(false);
  const [isNotification, setIsNotification] = useState(
    notificationModel(false, true, "Message")
  );
  const dispatch = useDispatch();

  const firstname = useRef();
  const lastname = useRef();
  const mobile = useRef();
  const address = useRef();
  const website = useRef();
  const country = useRef();
  const state = useRef();
  const city = useRef();
  const businessName = useRef();
  const contactInfo = useRef();

  const generateNotification = (type, message) => {
    setIsNotification(notificationModel(true, type, message));
    removeNotification();
  };

  const removeNotification = () =>
    setTimeout(
      () => setIsNotification(notificationModel(false, true, "")),
      2000
    );

  const updateHandler = async () => {
    setIsLoading(true);
    const updatedUserData = {};

    if (!(user.role === "User" || user.role === "Maintenance")) {
      if (website.current.value != "" && website.current.value != undefined)
        updatedUserData.website = website.current.value;
      if (
        businessName.current.value != "" &&
        businessName.current.value != undefined
      )
        updatedUserData.businessName = businessName.current.value;
    }

    if (firstname.current.value != "" && firstname.current.value != undefined)
      updatedUserData.firstName = firstname.current.value;
    if (lastname.current.value != "" && lastname.current.value != undefined)
      updatedUserData.lastName = lastname.current.value;
    if (mobile.current.value != "" && mobile.current.value != undefined)
      updatedUserData.mobile = mobile.current.value;
    if (address.current.value != "" && address.current.value != undefined)
      updatedUserData.address = address.current.value;

    if (country.current.value != "" && country.current.value != undefined)
      updatedUserData.country = country.current.value;
    if (state.current.value != "" && state.current.value != undefined)
      updatedUserData.state = state.current.value;
    if (city.current.value != "" && city.current.value != undefined)
      updatedUserData.city = city.current.value;

    if (
      contactInfo.current.value != "" &&
      contactInfo.current.value != undefined
    )
      updatedUserData.contactInfo = contactInfo.current.value;

    await axios
      .patch(
        "https://backend.resitic.com/api/v1/users/updateMe",
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        generateNotification(true, "Account Details Successfully updated");
      })
      .catch((err) => {
        generateNotification(
          false,
          "Something went wrong Account Details not updated"
        );
        setIsLoading(false);
      });

    const userInfo = await axios.get(
      "https://backend.resitic.com/api/v1/users/getMe",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(userActions.setUser(userInfo.data.data.user));
  };

  const UploadImageHandler = async (e) => {
    if (e.target.files[0] == null || e.target.files[0] == undefined) return;
    setIsPickLoading(true);
    const payload = new FormData();
    payload.append("profilePic", e.target.files[0]);
    try {
      await axios.patch(
        "https://backend.resitic.com/api/v1/users/updateDP",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userInfo = await axios.get(
        "https://backend.resitic.com/api/v1/users/getMe",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      generateNotification(true, "Profile Pick Successfully updated");
      dispatch(userActions.setUser(userInfo.data.data.user));
    } catch (err) {
      generateNotification(
        false,
        "Something Went wrong please try again letter"
      );
    }

    setIsPickLoading(false);
  };

  return (
    <>
      {isNotification.show === true && (
        <Notification
          type={isNotification.type}
          message={isNotification.message}
        />
      )}

      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row">
              <div className="col-12 col-sm-6 pb-3">
                <h3>My Account</h3>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 col-lg-12">
              <div className="card user-card-full">
                <div className="row m-l-0 m-r-0">
                  <div className="col-lg-4 col-md-12 col-sm-12 bg-c-lite-green user-profile">
                    <div className="card-block text-center text-white">
                      <div className="m-b-25 img-cover">
                        {isPickLoading && (
                          <div
                            className="loader"
                            style={{ position: "absolute" }}
                          ></div>
                        )}
                        <input
                          accept=".png, .jpg, .jpeg"
                          type="file"
                          id="profileImage"
                          name="profileImage"
                          className="upload-img"
                          onChange={UploadImageHandler}
                        />
                        <img
                          src={
                            user.profilePic != "user.png"
                              ? `https://backend.resitic.com/uploads/${user.profilePic}`
                              : userImage
                          }
                          className="img-radius"
                          alt="User-Profile"
                        />
                      </div>
                      <h6 className="user-name">
                        {user.firstname + " " + user.lastname}
                      </h6>
                      <p>{user.role}</p>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12 col-sm-12">
                    <div className="card-block">
                      <h6 className=" mb-3  b-b-default f-w-600 HeadingH6">
                        Information
                      </h6>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="mb-2">First Name</p>
                          <h6
                            className="text-immuted"
                            contentEditable="true"
                            ref={firstname}
                            onInput={(e) =>
                              (firstname.current.value =
                                e.currentTarget.textContent)
                            }
                          >
                            {user.firstname}
                          </h6>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="mb-2">Last Name</p>
                          <h6
                            className="text-immuted "
                            contentEditable="true"
                            ref={lastname}
                            onInput={(e) =>
                              (lastname.current.value =
                                e.currentTarget.textContent)
                            }
                          >
                            {user.lastname}
                          </h6>
                        </div>
                      </div>
                      <h6 className="mb-3 mt-5 b-b-default HeadingH6">
                        Contact Details
                      </h6>
                      <div className="row mb-3">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p style={{ color: "#AAAFB4 " }} className="mb-2">
                            Email
                          </p>
                          <h6
                            style={{
                              color: "#AAAFB4 ",
                              fontSize: "18px",
                              textTransform: "none",
                            }}
                            contentEditable="flase"
                          >
                            {user.email}
                          </h6>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="mb-2">Phone</p>
                          <h6
                            className="text-immuted"
                            contentEditable="true"
                            style={{
                              color: "#AAAFB4 ",
                              fontSize: "18px",
                              textTransform: "none",
                            }}
                            onInput={(e) =>
                              (mobile.current.value =
                                e.currentTarget.textContent)
                            }
                            ref={mobile}
                          >
                            {user.mobile}
                          </h6>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="mb-2">Country</p>
                          <h6
                            className="text-immuted"
                            contentEditable="true"
                            ref={country}
                            onInput={(e) =>
                              (country.current.value =
                                e.currentTarget.textContent)
                            }
                            style={{
                              textTransform: "none",
                            }}
                          >
                            {user.country}
                          </h6>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="mb-2">State</p>
                          <h6
                            className="text-immuted"
                            contentEditable="true"
                            ref={state}
                            onInput={(e) =>
                              (state.current.value =
                                e.currentTarget.textContent)
                            }
                            style={{
                              textTransform: "none",
                            }}
                          >
                            {user.state}
                          </h6>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="mb-2">City</p>
                          <h6
                            className="text-immuted"
                            contentEditable="true"
                            ref={city}
                            onInput={(e) =>
                              (city.current.value = e.currentTarget.textContent)
                            }
                            style={{
                              textTransform: "none",
                            }}
                          >
                            {user.city}
                          </h6>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <p className="mb-2">Contact Info</p>
                          <h6
                            className="text-immuted "
                            contentEditable="true"
                            ref={contactInfo}
                            onInput={(e) =>
                              (contactInfo.current.value =
                                e.currentTarget.textContent)
                            }
                            style={{
                              textTransform: "none",
                            }}
                          >
                            {user.contactInfo}
                          </h6>
                        </div>
                      </div>
                      {(user.role == "Admin" || user.role == "SuperAdmin") && (
                        <div className="row mb-3">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className="mb-2">Business Name</p>
                            <h6
                              className="text-immuted"
                              contentEditable="true"
                              ref={businessName}
                              onInput={(e) =>
                                (businessName.current.value =
                                  e.currentTarget.textContent)
                              }
                              style={{
                                textTransform: "none",
                              }}
                            >
                              {user.businessName}
                            </h6>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className="mb-2">Website</p>
                            <h6
                              className="text-immuted "
                              contentEditable="true"
                              ref={website}
                              onInput={(e) =>
                                (website.current.value =
                                  e.currentTarget.textContent)
                              }
                              style={{
                                textTransform: "none",
                              }}
                            >
                              {user.website}
                            </h6>
                          </div>
                        </div>
                      )}

                      <div className="row mb-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <p className="mb-2">Address</p>
                          <h6
                            className="text-immuted"
                            contentEditable="true"
                            ref={address}
                            onInput={(e) =>
                              (address.current.value =
                                e.currentTarget.textContent)
                            }
                            style={{
                              textTransform: "none",
                            }}
                          >
                            {user.address}
                          </h6>
                        </div>
                      </div>
                      <button
                        onClick={updateHandler}
                        className="btn btn-primary mt-4"
                        to="/add-business"
                      >
                        {!isLoading ? "Update Profile" : "Updating...."}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Account;
