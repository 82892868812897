import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import axios from "axios";
import Notification from "../components/notification";

const notificationModel = (show, type, message) => {
  return {
    show: show,
    type: type == true ? "success" : "fail",
    message: message,
  };
};

function Editticket() {
  const token = useSelector((state) => state.userInfo.token);
  const user = useSelector((state) => state.userInfo.user);
  const { id } = useParams();
  const [ticket, setTicket] = useState({});
  const [comments, setComments] = useState([]);
  const [commentsSubmitting, setCommentsSubmitting] = useState(false);
  const [maintenance, setMaintenance] = useState([]);
  const [maintenancePerson, setMaintenancePerson] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [uploadFile, setUploadFile] = useState(null);
  const [isNotification, setIsNotification] = useState(
    notificationModel(false, true, "Message")
  );
  const [isMaintenancePersonError, setIsMaintenancePersonError] =
    useState(false);
  const [uploadFileName, setUploadFileName] = useState(
    "Upload picture (Optional)"
  );
  const data = new FormData();
  const [load, setLoad] = useState({});
  const [change, setChange] = useState(false);
  const [changeComments, setChangeComments] = useState(false);

  const onfileChange = (e) => {
    setUploadFile(e.target.files[0]);
    setUploadFileName(e.target.files[0].name);
  };

  const generateNotification = (type, message) => {
    setIsNotification(notificationModel(true, type, message));
    removeNotification();
    setIsDataLoading(false);
  };

  const removeNotification = () =>
    setTimeout(
      () => setIsNotification(notificationModel(false, true, "")),
      2000
    );

  const editTicketHandler = async (e) => {
    e.preventDefault();
    setIsDataLoading(true);
    if (user.role === "Admin") {
      if (e.target.elements["subject"].value.trim() == "") {
        generateNotification(false, "Please Enter Subject");
        return;
      } else if (e.target.elements["personality"].value == "0") {
        generateNotification(false, "Please Choose Maintenance Person");
        return;
      } else {
        const ticketData = {
          tagId: +e.target.elements["personality"].value,
          priorityId: e.target.elements["priorityId"].value,
          statusId: e.target.elements["statusId"].value,
          description: e.target.elements["description"].value.trim(),
          subject: e.target.elements["subject"].value.trim(),
        };
        await axios
          .patch(
            `https://backend.resitic.com/api/v1/tickets/${id}`,
            ticketData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setIsDataLoading(false);
            setChange((previous) => !previous);
            generateNotification(true, "Tickets successfully Updated");
          })
          .catch((err) => {
            console.log(err);
            setIsDataLoading(false);
            setChange((previous) => !previous);
          });
      }
    } else {
      const statusdata = {
        statusId: parseInt(e.target.elements["statusId"].value),
      };
      await axios
        .patch(
          `https://backend.resitic.com/api/v1/tickets/editTicketStatus/${id}`,
          statusdata,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsDataLoading(false);
          setChange(true);
          generateNotification(true, "Tickets successfully Updated");
        })
        .catch((err) => {
          console.log(err);
          setIsDataLoading(false);
          setChange(true);
          generateNotification(
            false,
            "Something went wrong ticket Status not updated"
          );
        });
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setCommentsSubmitting(true);
    data.append("uploads", uploadFile);
    data.append("comment", e.target.elements[1].value);
    await axios
      .post(`https://backend.resitic.com/api/v1/comments/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          ContentType: "multipart/form-data",
        },
      })
      .then((response) => {
        setChangeComments((previous) => !previous);
        setUploadFileName("Upload picture (Optional)");
        e.target.elements["comment"].value = "";
      })
      .catch((err) => console.log(err));

    setCommentsSubmitting(false);
  };
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const commentsResponse = await axios.get(
          `https://backend.resitic.com/api/v1/comments/getTicketComments/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setComments(commentsResponse.data.result.data.rows.reverse());
      } catch (error) {}
    };
    fetchComments();
  }, [changeComments]);
  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      try {
        const response = await axios.get(
          `https://backend.resitic.com/api/v1/tickets/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const TagId = response.data.result.data.tagId;

        setTicket(response.data.result.data);

        if (user.role == "Admin") {
          const MaintenanceResponse = await axios.get(
            `https://backend.resitic.com/api/v1/users/getAllMaintenance`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (TagId != null)
            setMaintenancePerson(
              MaintenanceResponse.data.data.users.rows[
                MaintenanceResponse.data.data.users.rows.findIndex(
                  (Maintenance) => {
                    return Maintenance.id === TagId;
                  }
                )
              ]
            );
          setMaintenance(MaintenanceResponse.data.data.users.rows);
        }

        setIsDataLoading(false);
      } catch (err) {
        console.log(err);
        setIsDataLoading(false);
      }
      setIsDataLoading(false);
    };
    fetchData();
  }, [change]);

  const reloadCommentHandler = () => {
    setChangeComments((previous) => !previous);
  };

  const labelStyle =
    maintenancePerson != null
      ? {
          left: "0px",
          top: "-18px",
          fontSize: "14px",
        }
      : {};
  return (
    <>
      {isNotification.show === true && (
        <Notification
          type={isNotification.type}
          message={isNotification.message}
        />
      )}

      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row ">
              <div className="col-lg-6 col-md-12 col-sm-12 pb-3 sticky-top sticky-form">
                <h3 className="pt-3">Edit Ticket</h3>
                <div className="card form-holder mt-3">
                  {isDataLoading && (
                    <div className="LoaderBackground">
                      {" "}
                      <div className="loader"></div>
                    </div>
                  )}
                  <div className="card-body">
                    <form className="" onSubmit={editTicketHandler}>
                      <div className="text-group">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder=" "
                          required="required"
                          // disabled
                          defaultValue={ticket.subject}
                          disabled={user.role !== "Admin"}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label className="disableLabel">Subject</label>
                      </div>

                      <div className="text-group">
                        <select
                          className="form-control"
                          id="priorityId"
                          required=""
                          defaultValue={ticket.priorityId}
                          disabled={user.role !== "Admin"}
                        >
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                        </select>
                        <label
                          for="textarea"
                          className="input-label disableLabel"
                        >
                          Priority
                        </label>
                        <i className="bar"></i>
                      </div>
                      {user.role === "Admin" && (
                        <>
                          <div className="text-group">
                            <select
                              className="form-control"
                              id="personality"
                              required=""
                              style={
                                isMaintenancePersonError
                                  ? {
                                      background: "#ffe3e3",
                                    }
                                  : {}
                              }
                              disabled={user.role !== "Admin"}
                              onClick={(e) =>
                                e.target.value == "0"
                                  ? setIsMaintenancePersonError(true)
                                  : setIsMaintenancePersonError(false)
                              }
                            >
                              <option value="0">
                                Select Maintenance person
                              </option>
                              {maintenance.map((m) => {
                                return (
                                  <option
                                    key={m.id}
                                    value={m.id}
                                    selected={
                                      maintenancePerson != null
                                        ? m.id == maintenancePerson.id
                                          ? "selected"
                                          : ""
                                        : ""
                                    }
                                  >
                                    {m.firstName + " " + m.lastName}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              style={labelStyle}
                              for="textarea"
                              class="input-label"
                            >
                              Maintenance person
                            </label>
                            <i class="bar"></i>
                          </div>
                        </>
                      )}
                      {
                        <div class="text-group">
                          <select
                            disabled={
                              !(
                                user.role === "Maintenance" ||
                                user.role === "Admin"
                              )
                            }
                            class="form-control"
                            id="statusId"
                            required=""
                          >
                            <option value="1" selected={ticket.statusId == "1"}>
                              No Status
                            </option>
                            <option value="2" selected={ticket.statusId == "2"}>
                              Pending
                            </option>
                            <option value="3" selected={ticket.statusId == "3"}>
                              Completed
                            </option>
                          </select>
                          <label
                            for="textarea"
                            class="input-label disableLabel"
                          >
                            Status
                          </label>
                          <i class="bar"></i>
                        </div>
                      }
                      <div className="form-group group">
                        <div className="text-group">
                          <textarea
                            required="required"
                            className="form-control"
                            rows="3"
                            placeholder=" "
                            id="description"
                            defaultValue={ticket.description}
                            disabled={user.role !== "Admin"}
                          ></textarea>
                          <label
                            for="textarea"
                            className="input-label disableLabel"
                          >
                            Description
                          </label>
                          <i className="bar"></i>
                        </div>
                        {(user.role === "Admin" ||
                          user.role === "Maintenance") && (
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg"
                          >
                            Update Ticket
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 pb-3 pt-3 ">
                <h4 className="">Add Comment</h4>
                <div className="card form-holder mt-3">
                  <div className="card-body">
                    <form className="text-left" onSubmit={submitHandler}>
                      <div className="form-group">
                        <div className="custom-file pmd-custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="logo"
                            required=""
                            placeholder=" "
                            onChange={onfileChange}
                          />
                          <label className="custom-file-label" htmlFor="logo">
                            {uploadFileName}
                          </label>
                        </div>
                      </div>
                      <div className="form-group group">
                        <div className="text-group">
                          <textarea
                            required="required"
                            className="form-control"
                            rows="3"
                            placeholder=" "
                            id="comment"
                          ></textarea>
                          <label for="textarea" className="input-label">
                            Add Comment
                          </label>
                          <i className="bar"></i>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                          disabled={commentsSubmitting}
                        >
                          {commentsSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                {comments.length == 0 && (
                  <div
                    className="alert alert-danger noText"
                    role="alert"
                    style={{ width: "100%" }}
                  >
                    No comments Found
                  </div>
                )}

                {comments.length > 0 && (
                  <h4 className=" mt-5" style={{ display: "flex" }}>
                    Comments{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35.955"
                      height="36"
                      viewBox="0 0 35.955 36"
                      className="reload-svgIcon"
                      onClick={reloadCommentHandler}
                    >
                      <path
                        id="Icon_open-reload"
                        data-name="Icon open-reload"
                        d="M18,0A18,18,0,1,0,30.78,30.78l-3.24-3.24A13.51,13.51,0,1,1,17.955,4.5a13.091,13.091,0,0,1,9.4,4.095L22.455,13.5h13.5V0L30.6,5.355A17.9,17.9,0,0,0,17.955,0Z"
                        fill="#6871e1"
                      />
                    </svg>
                  </h4>
                )}

                {comments.length > 0 &&
                  comments.map((comment, index) => {
                    return (
                      <div
                        key={index}
                        className="card mt-3 comment-box"
                        style={
                          comment.userId == user.id
                            ? { background: "rgb(248 248 248)" }
                            : {}
                        }
                      >
                        <div className="card-body">
                          <h5 className="card-title">
                            {comment.userId == user.id
                              ? "From Me"
                              : "From " + comment.user.role}{" "}
                            ({" "}
                            {comment.user.firstName +
                              " " +
                              comment.user.lastName}{" "}
                            )
                          </h5>
                          <p className="card-text">{comment.comment}</p>
                          {comment.uploads.length !== 0 && (
                            <>
                              <div className="row show-photo-button-row">
                                <div
                                  className="col-sm-12 show-photo-button"
                                  onClick={() =>
                                    setLoad((previous) => {
                                      return {
                                        ...previous,
                                        [comment.id]: previous[comment.id]
                                          ? !previous[comment.id]
                                          : true,
                                      };
                                    })
                                  }
                                >
                                  <p className="card-text py-2 ">Show Photo</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-chevron-down"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                    />
                                  </svg>
                                </div>
                              </div>
                              {load[comment.id] && load[comment.id] == true && (
                                <div className="m-b-25  comments-Images">
                                  <img
                                    src={`https://backend.resitic.com/uploads/${comment.uploads[0]}`}
                                    className="img-radius"
                                    alt="User-Profile"
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Editticket;
