import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { useSelector } from "react-redux";
import axios from "axios";
import Modal from "react-modal";

function ManageUser() {
  const token = useSelector((state) => state.userInfo.token);
  const [users, setUsers] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [show, setshow] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { id } = useParams();
  let navigate = useNavigate();

  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  const DeleteHandler = () => {
    const deleteData = async () => {
      await axios
        .delete(`https://backend.resitic.com/api/v1/users/deleteUser/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          navigate("/manage-user");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    deleteData();
    handleClose();
  };

  const customStyles = {
    content: {
      background: "none",
      border: "none",
      zIndex: "1005",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.resitic.com/api/v1/users/getAllUsers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUsers(response.data.data.users.rows);
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDataLoading(false);
        });
      await axios
        .get("https://backend.resitic.com/api/v1/users/getAllMaintenance", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setMaintenance(response.data.data.users.rows);
          setIsDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDataLoading(false);
        });
    };
    fetchData();
  }, []);
  return (
    <>
      <div>
        <Sidebar />
        <main role="main" className="main">
          <Topbar />
          <div className="main-body pt-3 pb-2 pl-5 pr-5">
            <div className="page-title">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-6 pt-3 pb-3 d-flex justify-content-between user-div">
                  <h4>Manage Users</h4>
                  <Link className="btn btn-primary" to="/add-user">
                    Add New User
                  </Link>
                </div>
              </div>
            </div>
            <div className="table-responsive mt-3">
              {isDataLoading && <div className="loader"></div>}
              {!isDataLoading &&
                (users.length != 0 || maintenance.length !== 0) && (
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => {
                        return (
                          <>
                            <tr key={user.id}>
                              <td>{user.id}</td>
                              <td>{user.firstName + " " + user.lastName}</td>
                              <td>{user.email}</td>
                              <td>{user.mobile}</td>
                              <td>{user.role}</td>
                              <td>
                                <Link
                                  className="btn btn-primary"
                                  to={`/edit-user/${user.id}`}
                                >
                                  Edit
                                </Link>
                                <Link
                                  className="btn btn-danger"
                                  to={`/manage-user/${user.id}`}
                                  onClick={handleShow}
                                >
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      {maintenance.map((m, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{m.id}</td>
                              <td>{m.firstName + " " + m.lastName}</td>
                              <td>{m.email}</td>
                              <td>{m.mobile}</td>
                              <td>{m.role}</td>
                              <td>
                                <Link
                                  className="btn btn-primary"
                                  to={`/edit-user/${m.id}`}
                                >
                                  Edit
                                </Link>
                                <Link
                                  className="btn btn-danger"
                                  to={`/manage-user/${m.id}`}
                                  onClick={handleShow}
                                >
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                )}

              {!isDataLoading && users.length == 0 && maintenance.length == 0 && (
                <div
                  className="alert alert-danger noText"
                  role="alert"
                  style={{ width: "50%" }}
                >
                  No Users Found
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
      {/* ---------------------------Modal----------------------- */}
      <Modal isOpen={show} onHide={() => setshow(false)} style={customStyles}>
        <div class="modal-dialog modal-confirm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column">
              <div class="icon-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
              <h4 class="modal-title w-100">Are you sure?</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <p>
                Do you really want to delete these records? This process cannot
                be undone.
              </p>
            </div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={DeleteHandler}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ManageUser;
