import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { mobileNumberFormatter } from "../Utils/Healper";

function EditBusiness() {
  const { id } = useParams();
  const token = useSelector((state) => state.userInfo.token);
  const [business, setBusiness] = useState({});
  const [logoFileName, setLogoFileName] = useState("Update Logo");
  const [logoFile, setLogoFile] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const navigate = useNavigate();
  const [state, updateState] = useState(false);
  const profilePicUrl = "https://backend.resitic.com/uploads/";
  const submitHandler = async (e) => {
    e.preventDefault();

    const updatedBusiness = {
      firstName: e.target.elements["firstName"].value,
      lastName: e.target.elements["lastName"].value,
      email: e.target.elements["email"].value,
      password: e.target.elements["password"].value,
      mobile: e.target.elements["mobile"].value
        .replace("(", "")
        .replace(")", "")
        .replace(" - ", "")
        .replace(" ", ""),
      notes: e.target.elements["notes"].value,
      city: e.target.elements["city"].value,
      state: e.target.elements["state"].value,
      country: e.target.elements["country"].value,
      contactInfo: e.target.elements["contactInfo"].value,
      address: e.target.elements["address"].value,
      address2: e.target.elements["address2"].value,
      website: e.target.elements["website"].value,
      businessName: e.target.elements["businessName"].value,
    };
    if (
      business != {} &&
      business.mobile != e.target.elements["mobile"].value
    ) {
      updatedBusiness.mobile = e.target.elements["mobile"].value
        .replace("(", "")
        .replace(")", "")
        .replace(" - ", "")
        .replace(" ", "");
    }

    await axios
      .patch(
        `https://backend.resitic.com/api/v1/users/updateBusinessDetails/${id}`,
        updatedBusiness,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        navigate("/manage-businesses");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.resitic.com/api/v1/users/getOne/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBusiness(response.data.data.users);
        setIsDataLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [state]);
  const onFileChange = (e) => {
    setIsDataLoading(true);
    setLogoFile(e.target.files[0]);
    setLogoFileName(e.target.files[0].name);
    const formData = new FormData();
    formData.append("profilePic", e.target.files[0]);
    axios
      .patch(
        `https://backend.resitic.com/api/v1/users/updatePic/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        updateState((current) => !current);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 col-sm-6 pb-3">
                <h3 className="pt-3">Update Business</h3>
                <div className="card form-holder mt-3">
                  <div className="card-body">
                    {isDataLoading && (
                      <div className="loadingWrapper">
                        <div class="loader"></div>
                      </div>
                    )}
                    {!isDataLoading && (
                      <img
                        className="edit-business-logo"
                        src={profilePicUrl + business.profilePic}
                        alt="logo"
                      />
                    )}
                    <form className="business-form" onSubmit={submitHandler}>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-group">
                            <div className="custom-file pmd-custom-file">
                              <input
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                className="custom-file-input"
                                id="logo"
                                required=""
                                onChange={onFileChange}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="logo"
                              >
                                {logoFileName}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="businessName"
                              defaultValue={business.businessName}
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Business Name</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="firstName"
                              required="required"
                              placeholder=" "
                              defaultValue={business.firstName}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>First Name</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              placeholder=" "
                              required="required"
                              defaultValue={business.lastName}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Last Name</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder=" "
                              required="required"
                              defaultValue={business.email}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Email</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="password"
                              placeholder=" "
                              required="required"
                              defaultValue={business.password}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Password</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="mobile"
                              placeholder=" "
                              required="required"
                              defaultValue={mobileNumberFormatter(
                                business.mobile
                              )}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Add Business Number</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="website"
                              placeholder=" "
                              required="required"
                              defaultValue={business.website}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Website</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder=" "
                              required="required"
                              defaultValue={business.address}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Address Line 1</label>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="address2"
                              placeholder=" "
                              required="required"
                              defaultValue={business.address2}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Address Line 2</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="country"
                              placeholder=" "
                              required="required"
                              defaultValue={business.country}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Country</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              placeholder=" "
                              required="required"
                              defaultValue={business.state}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>State</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              placeholder=" "
                              required="required"
                              defaultValue={business.city}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>City</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder=" "
                              id="contactInfo"
                              required="required"
                              defaultValue={business.contactInfo}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Business Contact Name</label>
                          </div>
                        </div>
                      </div>

                      <div className="text-group">
                        <textarea
                          required="required"
                          className="form-control"
                          rows="3"
                          id="notes"
                          placeholder=" "
                          defaultValue={business.notes}
                        ></textarea>
                        <label htmlFor="textarea" className="input-label">
                          Notes
                        </label>
                        <i className="bar"></i>
                      </div>

                      <div className="form-group group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                        >
                          UPDATE
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="cartoon-img">
                <img
                  className="landing-img"
                  src="assets/img/images.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default EditBusiness;
