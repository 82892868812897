import React from "react";
import { Link } from "react-router-dom";
import CONSTANTS from "../Utils/CONSTANTS";

export default function TableComponent({
  headers,
  rawData,
  handleShow,
  isShowAssignTag,
}) {
  return (
    <table className="table table-striped table-sm">
      <thead>
        <tr>
          {headers.map((heading, index) => (
            <th
              key={"heading" + index}
              style={{ textAlign: heading.align ? heading.align : "left" }}
            >
              {heading.value}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rawData.map((data) => {
          return (
            <tr key={data.id}>
              <td style={{ textAlign: "center" }}>{data.id}</td>
              
              <td>
                {CONSTANTS.STATUS[data.statusId]}{" "}
                {isShowAssignTag && (
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      color: CONSTANTS.STATUS[data.tagId == null ? 4 : 5].color,
                    }}
                  >
                    {CONSTANTS.STATUS[data.tagId == null ? 4 : 5].value}
                  </div>
                )}
              </td>
              <td>{data.subject}</td>

              <td
                style={{
                  textAlign: "center",
                  color: CONSTANTS.PRIORITY[data.priorityId].color,
                }}
              >
                {CONSTANTS.PRIORITY[data.priorityId].value}
              </td>
              <td
                style={{
                  textAlign: "center",
                  color: CONSTANTS.PRIORITY[data.priorityId].color,
                }}
              >
                {CONSTANTS.PRIORITY[data.priorityId].value}
              </td>
              <td>
                <Link
                  className="btn btn-primary"
                  to={`/edit-ticket/${data.id}`}
                >
                  View / Edit
                </Link>
                <div
                  className="btn btn-danger"
                  // to={`/unresolved-tickets/${data.id}`}
                  data-toggle="modal"
                  style={{ fontSize: "10px" }}
                  onClick={() => handleShow(data.id)}
                >
                  Delete
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

TableComponent.defaultProps = {
  headers: CONSTANTS.COMMUNE_TABLE_COLUMN,
  rawData: [],
  isActionField: true,
  isEditButton: true,
  isDeleteButton: true,
  isShowAssignTag: false,
};
