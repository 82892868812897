export const Status = {
    "1" : 'Open',
    "2" : 'InProgress',
    "3" : 'Done'
}

export const Priority = {
    1 : 'Low',
    2 : 'Medium',
    3 : 'High'
}

export const customStylesforModal = {
    content: {
      background: 'none',
      border: 'none',
      zIndex : '1005',
    },
  };