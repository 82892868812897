import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Dashboard() {
  const user = useSelector((state) => state.userInfo.user);

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-sm-6 pb-3">
            <h3>Dashboard</h3>
          </div>
        </div>
      </div>
      <div className="card profile-greeting">
        <div className="card-body">
          <div className="media">
            <div className="media-body">
              <div className="greeting-user">
                <h1>
                  Hello, {user.firstname} {user.lastname}
                </h1>
                <p>Welcome back, your dashboard is ready!</p>
                <Link className="btn btn-outline-white_color" to="/account">
                  VIEW / Edit Profile<i className="fa fa-arrow-right"> </i>
                </Link>
              </div>
            </div>
          </div>
          <div className="cartoon-img">
            <img className="landing-img" src="assets/img/images.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
