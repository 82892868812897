import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notification from "../components/notification";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { mobileNumberFormatter } from "../Utils/Healper";

function AddBusiness() {
  const token = useSelector((state) => state.userInfo.token);
  const data = new FormData();
  const [logoFileName, setLogoFileName] = useState("Select Logo *");
  const [logoFile, setLogoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isNotification, setIsNotification] = useState(null);
  const navigate = useNavigate();

  const generateNotification = (type, message) => {
    setIsNotification({ type, message });
    setTimeout(() => setIsNotification(null), 1500);
  };
  const onfileChange = (e) => {
    setLogoFile(e.target.files[0]);
    setLogoFileName(e.target.files[0].name);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      e.target.elements["password"].value !==
      e.target.elements["confirmPassword"].value
    ) {
      generateNotification("fail", "Confirm Password Not matched");
      return;
    }
    setIsLoading(true);
    data.append("firstName", e.target.elements["firstName"].value);
    data.append("lastName", e.target.elements["lastName"].value);
    data.append("email", e.target.elements["email"].value);
    data.append("password", e.target.elements["password"].value);
    data.append(
      "mobile",
      e.target.elements["mobile"].value
        .replace("(", "")
        .replace(")", "")
        .replace(" - ", "")
        .replace(" ", "")
    );
    data.append("address", e.target.elements["address"].value);
    data.append("address2", e.target.elements["address2"].value);
    data.append("website", e.target.elements["website"].value);
    data.append("zipCode", e.target.elements["zipCode"].value);
    data.append("businessName", e.target.elements["businessName"].value);
    data.append("notes", e.target.elements["notes"].value);
    data.append("city", e.target.elements["city"].value);
    data.append("state", e.target.elements["state"].value);
    data.append("country", e.target.elements["country"].value);
    data.append("contactInfo", e.target.elements["contactInfo"].value);

    data.append("profilePic", logoFile);
    await axios
      .post("https://backend.resitic.com/api/v1/users/addUser", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === "fail") {
          console.log(response.data.message);
          generateNotification("fail", response.data.message);
        } else {
          navigate("/manage-businesses");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err?.response?.data?.message);
        generateNotification("fail", err?.response?.data?.message);
      });
  };

  return (
    <>
      {isNotification != null && (
        <Notification
          type={isNotification.type}
          message={isNotification.message}
        />
      )}
      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 col-sm-6 pb-3">
                <h3 className="pt-3">Add a Business</h3>
                <div className="card form-holder mt-3">
                  <div className="card-body">
                    <form className="business-form" onSubmit={submitHandler}>
                      {/* <div className="group form-group">      
                            <input type="file" className="form-control" id="name" required=""/>
                                <span className="highlight"></span>
                                <span className="bar"></span>
                            <label>Logo</label>
                          </div> */}
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="form-group">
                            <div className="custom-file pmd-custom-file">
                              <input
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                className="custom-file-input"
                                id="logo"
                                required="required"
                                onChange={onfileChange}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="logo"
                              >
                                {logoFileName}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="firstName"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>First Name *</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Last Name *</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="businessName"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Business Name *</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="password"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Password *</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="confirmPassword"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Confirm Password *</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Email *</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="website"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Website *</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="mobile"
                              placeholder=" "
                              required="required"
                              onInput={(e) => setMobileNumber(e.target.value)}
                              value={mobileNumberFormatter(mobileNumber)}
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Add Business Number *</label>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="contactInfo"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Business Contact Name *</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Address Line 1 *</label>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="address2"
                              name="address2"
                              placeholder=" "
                            />
                            <label form="address2">Address Line 2</label>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>State *</label>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>City *</label>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="zipCode"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Zip code *</label>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div className="group form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="country"
                              required="required"
                              placeholder=" "
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Country *</label>
                          </div>
                        </div>
                      </div>

                      <div className="text-group">
                        <textarea
                          className="form-control"
                          rows="3"
                          id="notes"
                          placeholder=" "
                        ></textarea>
                        <label htmlFor="textarea" className="input-label">
                          Notes
                        </label>
                        <i className="bar"></i>
                      </div>

                      <div className="form-group group">
                        {!isLoading ? (
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg"
                          >
                            ADD
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning btn-lg"
                          >
                            Adding......
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <div className="cartoon-img">
                <img className="img-fluid" src="assets/img/images.svg" alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AddBusiness;
