import React from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";

function UserNotification() {
  return (
    <>
      <Sidebar />
      <main role="main" className="main">
        <Topbar />
        <div className="main-body pt-3 pb-2 pl-5 pr-5">
          <div className="page-title">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-6 pb-3">
                <h3 className="pt-3">Send User Notification</h3>

                <div className="card form-holder mt-3">
                  <div className="card-body">
                    <form className="">
                      <div className="form-group group">
                        <input
                          type="email"
                          className="form-control"
                          id="name"
                          placeholder=" "
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Email</label>
                      </div>
                      <div className="form-group group">
                        <input
                          type="mobileno"
                          className="form-control"
                          id="name"
                          placeholder=" "
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Phone</label>
                      </div>
                      <div className="form-group group">
                        <div className="text-group">
                          <textarea
                            required="required"
                            className="form-control"
                            rows="3"
                            placeholder=" "
                          ></textarea>
                          <label for="textarea" className="input-label">
                            Description
                          </label>
                          <i className="bar"></i>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="cartoon-img">
                <img className="img-fluid" src="assets/img/images.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default UserNotification;
