import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logoimg from "../images/logo.png";
import CONSTANTS from "../Utils/CONSTANTS";
import NavItem from "./nav-item";
function Sidebar() {
  const user = useSelector((state) => state.userInfo.user);
  const IsActive = () => {
    return window.location.pathname;
  };

  const NavigationMenu = CONSTANTS.NAVIGATION_MENU.filter(
    (menu) => menu.isHide === false
  );
  return (
    <>
      <nav id="sidebarMenu" className=" d-md-block bg-light sidebar collapse">
        <div className="sidebar-sticky pt-3">
          <ul className="nav flex-column">
            <li className="nav-item pb-3">
              <Link
                className="navbar-brand col-md-3 col-lg-2 mr-0 px-3"
                to={user.role !== "SuperAdmin" ? "/" : "/manage-businesses"}
              >
                <img alt="" src={logoimg} />
              </Link>
            </li>
            {NavigationMenu.map((menu) => (
              <li className="nav-item">
                <NavItem
                  key={menu.id}
                  isActive={IsActive() == menu.navigateTo}
                  {...menu}
                  isShow={menu.role[user.role] ? true : false}
                />
              </li>
            ))}

            <li className="nav-item desktop-hide">
              <Link className="nav-link" to="google.com">
                <div className="left-side-header">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#000000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search here.."
                      aria-label="search"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
